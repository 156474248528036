@import "colorful.scss";

body{
    max-width: 50rem;
    padding: 2rem;
    margin: auto;
    line-height: 1.4;
    font-size: 120%;
    color: #272727;
    background-color: #f0f0f0;
}

.code-display{
    border: 1px solid #272727;
    display: flex;
    flex-direction: column;
    max-width: 30rem;
    margin: 10px auto;
}

.code-display>canvas{
    margin: 0 auto;
}

.code-display>p, .code-display>input, .code-display>button{    
    margin: 5px auto;
}

header h2{
    font-size: 300%;
    margin: 0px;
}

header p{
    margin-top: 0px;
}

header a{
    font-size: 150%;
}

#nav-bar ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    width: 100%;
    justify-content: space-around;
}

.highlight{
    background-color: #272727;
}

#post-title{
    font-size: 120%;
}

#post-title h1{
    margin-bottom: 0px;
}

#post-title p{
    margin-top: 0px;
}