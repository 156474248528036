.highlighter-rouge > .highlight{
  padding: .5em 1em;
}

.highlight {
    .hll {
      background-color: #ffffcc;
    }
    .c {
      color: #808080;
    }
    .err {
      color: #F00000;
      background-color: #F0A0A0;
    }
    .k {
      color: #e6a938;
      font-weight: bold;
    }
    .o {
      color: #fff0f0;
    }
    .cm {
      color: #808080;
    }
    .cp {
      color: #507090;
    }
    .c1 {
      color: #808080;
    }
    .cs {
      color: #cc0000;
      font-weight: bold;
    }
    .gd {
      color: #A00000;
    }
    .ge {
      font-style: italic;
    }
    .gr {
      color: #FF0000;
    }
    .gh {
      color: #000080;
      font-weight: bold;
    }
    .gi {
      color: #00A000;
    }
    .go {
      color: #808080;
    }
    .gp {
      color: #c65d09;
      font-weight: bold;
    }
    .gs {
      font-weight: bold;
    }
    .gu {
      color: #800080;
      font-weight: bold;
    }
    .gt {
      color: #0040D0;
    }
    .kc, .kd, .kn {
      color: #e6a938;
      font-weight: bold;
    }
    .kp {
      color: #003080;
      font-weight: bold;
    }
    .kr {
      color: #008000;
      font-weight: bold;
    }
    .kt {
      color: #303090;
      font-weight: bold;
    }
    .m {
      color: #6000E0;
      font-weight: bold;
    }
    .s {
      background-color: #fff0f0;
    }
    .na {
      //color: #0000C0;
    }
    .nb {
      color: #74abf1;
    }
    .nc {
      color: #B00060;
      font-weight: bold;
    }
    .no {
      color: #003060;
      font-weight: bold;
    }
    .nd {
      color: #505050;
      font-weight: bold;
    }
    .ni {
      color: #800000;
      font-weight: bold;
    }
    .ne {
      color: #F00000;
      font-weight: bold;
    }
    .nf {
      color: #0060B0;
      font-weight: bold;
    }
    .nl {
      color: #907000;
      font-weight: bold;
    }
    .nn {
      color: #0e84b5;
      font-weight: bold;
    }
    .nt {
      color: #007000;
    }
    .nv {
      color: #906030;
    }
    .ow {
      color: #000000;
      font-weight: bold;
    }
    .w {
      color: #bbbbbb;
    }
    .mf {
      color: #8d8dec;
      font-weight: bold;
    }
    .mh {
      color: #005080;
      font-weight: bold;
    }
    .mi {
      color: #8d8dec;
      font-weight: bold;
    }
    .mo {
      color: #4000E0;
      font-weight: bold;
    }
    .sb {
      background-color: #fff0f0;
    }
    .sc {
      color: #0040D0;
    }
    .sd {
      color: #D04020;
    }
    .s2 {
      background-color: #fff0f0;
    }
    .se {
      color: #606060;
      font-weight: bold;
      background-color: #fff0f0;
    }
    .sh {
      background-color: #fff0f0;
    }
    .si {
      background-color: #e0e0e0;
    }
    .sx {
      color: #D02000;
      background-color: #fff0f0;
    }
    .sr {
      color: #000000;
      background-color: #fff0ff;
    }
    .s1 {
      //background-color: #fff0f0;
    }
    .ss {
      color: #A06000;
    }
    .bp {
      color: #007020;
    }
    .vc {
      color: #306090;
    }
    .vg {
      color: #d07000;
      font-weight: bold;
    }
    .vi {
      color: #3030B0;
    }
    .il {
      color: #0000D0;
      font-weight: bold;
    }
    * {
      color: #fff0f0;
    }
  }